import { Button, Flex, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { shippingDetailsSchema, ShippingDetails } from '@menesko/models-harmonia';
import { cn } from '@shared/design-system/utils/shadcn';
import { AxiosError } from 'axios';
import { forwardRef, ComponentProps, useState, ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { InputControl } from '~/components/form/input';
import { InputPhoneControl } from '~/components/form/input-phone';
import { SelectControl } from '~/components/form/select';
import { APIClient, getAPIUrl } from '~/firebase-init';
import { states } from '~/helpers/constants';
import { sleep } from '~/helpers/sleep';
import { useAppLead } from '~/hooks/useAppLead';

const statesOptions = states.map((state) => ({ label: state, value: state }));

type Option = { label: string; value: string };

export interface ShippingFormProps extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  onSubmit: (shippingDetails: ShippingDetails) => void;
  buttonCopy?: string;
  error?: AxiosError | null;
  isLoading?: boolean;
}

export const ShippingForm = forwardRef<HTMLFormElement, ShippingFormProps>(
  ({ className, onSubmit, buttonCopy = 'Continue', error, isLoading, ...props }, ref) => {
    const { email, shippingDetails } = useAppLead();
    const [isShippingLoading, setIsShippingLoading] = useState(false);
    const [addressOptions, setAddressOptions] = useState<Option[]>([]);
    const [searchQuery, setSearchQuery] = useState('');

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
      setValue,
    } = useForm<ShippingDetails>({
      resolver: yupResolver(shippingDetailsSchema),
      defaultValues: {
        firstName: shippingDetails?.firstName,
        lastName: shippingDetails?.lastName,
        apartment: shippingDetails?.apartment,
        city: shippingDetails?.city,
        zip: shippingDetails?.zip,
        email: shippingDetails?.email || email,
        phone: shippingDetails?.phone,
        country: 'United States (FREE shipping)',
        addressLine1: shippingDetails?.addressLine1,
        addressLine2: shippingDetails?.addressLine2,
        state: shippingDetails?.state,
      },
    });

    const handleFormSubmit = async (data: ShippingDetails) => {
      await sleep(500);

      const shippingDetails = {
        ...data,
        // Shipstation requires country to be in 2-character ISO 3116-1 format
        country: 'US',
      };

      onSubmit(shippingDetails);
    };

    const handleAddressChange = (el: ChangeEvent<HTMLInputElement>) => {
      const addressValue = el.target.value;

      if (addressValue?.length >= 3) {
        setIsShippingLoading(true);
        setSearchQuery(addressValue);
      } else {
        setAddressOptions([]);
        setSearchQuery('');
      }
    };

    const handleAddressOptionSelect = async (option: { label: string; value: string; placeId?: string }) => {
      setSearchQuery('');
      if (option?.placeId) {
        setIsShippingLoading(true);
        const { data } = await APIClient.get<{ zip: string; city: string; state: string; name: string }>(
          getAPIUrl('shipping/autocomplete'),
          {
            params: {
              placeId: option.placeId,
            },
          },
        )
          .catch(() => {
            return { data: null };
          })
          .finally(() => {
            setIsShippingLoading(false);
          });

        if (data) {
          if (data.zip) {
            setValue('zip', data.zip);
          }

          if (data.city) {
            setValue('city', data.city);
          }

          if (data.name) {
            setValue('addressLine1', data.name);
          }

          if (data.state) {
            setValue('state', data.state);
          }
        }
      }
    };

    useEffect(() => {
      const fetchSuggestions = setTimeout(async () => {
        const { data } = await APIClient.get<Option[]>(getAPIUrl('shipping/search'), {
          params: {
            search: searchQuery,
          },
        }).finally(() => {
          setIsShippingLoading(false);
        });

        setAddressOptions(() => {
          return [{ label: searchQuery, value: searchQuery }].concat(data || []);
        });
      }, 500);

      return () => clearTimeout(fetchSuggestions);
    }, [searchQuery]);

    return (
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className={cn('m-auto max-w-[24rem]', className)}
        {...props}
        ref={ref}
      >
        <Flex flexDir='column' gap='0.75rem' marginBottom='1.5rem'>
          <Flex gap='0.75rem'>
            <InputControl name='firstName' inputProps={{ placeholder: 'First name' }} control={control} />
            <InputControl name='lastName' inputProps={{ placeholder: 'Last name' }} control={control} />
          </Flex>
          <SelectControl
            name='addressLine1'
            inputProps={{
              placeholder: 'Address Line 1',
              onOptionSelect: handleAddressOptionSelect,
              noOptionsMessage: () => null,
              menuIsOpen: searchQuery.length >= 3,
              onBlur: () => setSearchQuery(''),
            }}
            control={control}
            options={addressOptions}
            onChange={handleAddressChange}
            isLoading={isShippingLoading}
          />
          <InputControl
            name='addressLine2'
            inputProps={{ placeholder: 'Address Line 2 (optional)' }}
            control={control}
          />
          <InputControl disabeld name='country' control={control} />
          <SelectControl name='state' inputProps={{ placeholder: 'State' }} control={control} options={statesOptions} />
          <div className='flex gap-3'>
            <InputControl name='city' inputProps={{ placeholder: 'Town / city' }} control={control} />
            <InputControl name='zip' inputProps={{ placeholder: 'Zip code' }} control={control} />
          </div>
          <InputControl name='email' inputProps={{ placeholder: 'Email address' }} control={control} />
          <InputPhoneControl name='phone' inputProps={{ placeholder: 'Phone number' }} control={control} />
        </Flex>
        {error ? (
          <Alert status='error' marginBottom='1rem' title='Error'>
            <AlertIcon />
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        ) : null}
        <Button type='submit' isLoading={isSubmitting || isLoading}>
          {buttonCopy}
        </Button>
      </form>
    );
  },
);

ShippingForm.displayName = 'ShippingForm';
